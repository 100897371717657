.popover {
    position: absolute;
    background: white;
    border-radius: 50%; /* Makes it circular */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1000; /* Ensure it appears above other content */
    display: flex; /* Enable flexbox */
    align-items: center;
    justify-content: center;
    width: auto; /* Allow width to adjust */
    height: 500px; /* Allow height to adjust */
    max-width: 80vw; /* Maximum width based on viewport */
    max-height: 500px; /* Maximum height based on viewport */
    margin-top: 5% ;
    margin-left: 30%;
    overflow: hidden;


}
.scan-options {
    display: flex; /* Enable flexbox */
    justify-content: space-around; /* Space around the buttons */
    margin-top: 20px; /* Space above the buttons */
    width: 100%; /* Ensure the container takes full width */
}


.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: gray;
}


body.popover-open {
    overflow: hidden; /* Prevent scrolling */
    pointer-events: none; /* Disable interaction */
}

body.popover-open .popover {
    pointer-events: auto; /* Allow interaction with the popover */
}
