body {
    min-width: 1000px; /* Prevent screen width reduction below 1000px */
    overflow: auto; /* Allow scrolling if content exceeds the viewport */
  }
  
  .data-table {
    width: 100%; /* Ensure the table takes full width */
    table-layout: auto; /* Use automatic layout for flexibility */
    overflow-x: hidden; /* Prevent horizontal overflow */
}
  
  .table-responsive {
    overflow-x: hidden; /* Allow horizontal scrolling if necessary */
  }
  
  .table {
    min-width: 0; /* Prevent minimum width to allow responsiveness */
  }
  

/* Responsive adjustments */
@media only screen and (max-width: 1800px) {
    th{
        padding: 4px;
        min-width: 40px
    }
    td {
      padding: 4px; /* Less padding for medium screens */
      min-width: 40px; /* Adjust min-width for smaller screens */
      max-width: 150px; /* Reduce max-width for smaller screens */
      width: auto; /* Allow columns to shrink */
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  
  @media only screen and (max-width: 1400px) {
    th{
        padding:3px;
        min-width: 30px
    }
    td {
      padding: 3px; /* Even less padding for small screens */
      min-width: 30px; /* Further adjust min-width for smaller screens */
      max-width: 100px; /* Further reduce max-width for smaller screens */
      width: auto; /* Allow columns to shrink */
      
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    th{
        padding: 2px;
        min-width: 20px
    }
    td {
      padding: 2px; /* Minimal padding for very small screens */
      min-width: 20px; /* Minimal width for very small screens */
      max-width: 80px; /* Minimal max-width for very small screens */
      width: auto; /* Allow columns to shrink */
      
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

   
  @media only screen and (max-width: 1000px) {
    th{
        padding: 1px;
        min-width: 10px
    }
    td {
      padding: 1px; /* Minimal padding for very small screens */
      min-width: 10px; /* Minimal width for very small screens */
      max-width: 60px; /* Minimal max-width for very small screens */
      width: auto; /* Allow columns to shrink */
      
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  @media only screen and (max-width: 800px) {
    th{
        padding: 0%;
        min-width: 4px
    }
    td {
      padding: 0px; /* Minimal padding for very small screens */
      min-width: 4px; /* Minimal width for very small screens */
      max-width: 10px; /* Minimal max-width for very small screens */
      width: auto; /* Allow columns to shrink */
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }